export default defineNuxtRouteMiddleware(async (to) => {
  const claim = useClaim()
  const claims = useClaims()
  const activeClaimId = useActiveClaim()
  const skeleton = useSideMenuSkeleton()
  const localePath = useLocalePath({ route: to })

  await fetchUserClaims()

  if (!claims.value.length || claims.value[0].status === 1)
    return navigateTo(localePath('developer-first-claim'))

  const hasIncompleteClaim = claims.value.some(claim => claim.status === 1)
  const activeClaim: AppGuide.Devs.Claim | undefined
    = claims.value.find(claim => claim.id === Number.parseInt(`${activeClaimId.value}`))
    ?? claims.value.find(claim => claim.status !== 1)

  if (hasIncompleteClaim || !activeClaim)
    return navigateTo(localePath('developer-claim'))

  // const activeClaim: AppGuide.Devs.Claim | undefined
  //   = claims.value.find(claim => claim.id === Number.parseInt(`${activeClaimId.value}`))
  //   ?? claims.value.find(claim => claim.status !== 1)

  // if (!activeClaim)
  //   return navigateTo(localePath('developer-claim'))

  skeleton.value = false
  claim.value = activeClaim
  activeClaimId.value = `${activeClaim.id}`
})
